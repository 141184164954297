<template>
  <div>
    <Html class="h-full bg-gray-50">
      <Head>
        <Title>WeTix - Login</Title>
      </Head>
    </Html>

    <Body class="h-full" />

    <div class="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
      <NuxtLink to="/" class="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
        <img src="~/assets/wetix-logo.svg" class="mr-4 h-11" alt="WeTix">
      </NuxtLink>
      <!-- Card -->
      <div
        class="items-center justify-center w-full bg-white rounded-lg shadow lg:flex md:mt-0 lg:max-w-screen-lg 2xl:max:max-w-screen-lg xl:p-0 dark:bg-gray-800"
      >
        <div class="hidden w-2/3 lg:flex">
          <img
            class="rounded-l-lg grayscale"
            src="~/assets/images/wetix_landing_bg_2.jpg"
            alt="Wetix event tickets login page"
          >
        </div>
        <div class="w-full p-6 space-y-8 sm:p-8 lg:p-16 lg:py-0">
          <h2 class="text-2xl font-bold text-gray-900 lg:text-3xl dark:text-white">
            Sign in to platform
          </h2>
          <client-only>
            <div v-if="!authState.isAuthed" class="">
              <div class="">
                <form class="space-y-6" @submit.prevent="initPasswordlessLogin()">
                  <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">Email
                      address</label>
                    <div class="mt-1">
                      <input
                        id="email"
                        v-model="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        placeholder="Enter your email..."
                        required
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      >
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      @click="initPasswordlessLogin()"
                    >
                      Sign
                      in with magic link
                    </button>
                  </div>
                </form>

                <div class="mt-6">
                  <div class="relative">
                    <div class="absolute inset-0 flex items-center">
                      <div class="w-full border-t border-gray-300" />
                    </div>
                    <div class="relative flex justify-center text-sm">
                      <span class="px-2 bg-white text-gray-500">Or</span>
                    </div>
                  </div>
                  <div>
                    <button
                      name="sign-in-with-google"
                      class="w-full mt-4 inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      @click="initGoogleLoginPopup"
                    >
                      <svg width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17 8.18371C17 7.63989 16.9551 7.09314 16.8591 6.55814H9.16046V9.63879H13.5691C13.3862 10.6324 12.7983 11.5113 11.9376 12.0698V14.0687H14.5678C16.1123 12.6754 17 10.6177 17 8.18371Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M9.16042 16C11.3617 16 13.2182 15.2916 14.5707 14.0687L11.9406 12.0698C11.2088 12.5578 10.2641 12.8341 9.16342 12.8341C7.03409 12.8341 5.22865 11.4261 4.58085 9.53299H1.8667V11.5936C3.25227 14.2951 6.07438 16 9.16042 16V16Z"
                          fill="#34A853"
                        />
                        <path
                          d="M4.57786 9.53298C4.23596 8.53941 4.23596 7.46353 4.57786 6.46996V4.40933H1.8667C0.709065 6.66985 0.709065 9.33309 1.8667 11.5936L4.57786 9.53298V9.53298Z"
                          fill="#FBBC04"
                        />
                        <path
                          d="M9.16042 3.16589C10.3241 3.14825 11.4487 3.57743 12.2914 4.36523L14.6217 2.0812C13.1462 0.72312 11.1878 -0.0235267 9.16042 -1.02057e-05C6.07438 -1.02057e-05 3.25227 1.70493 1.8667 4.40932L4.57785 6.46995C5.22265 4.57394 7.03109 3.16589 9.16042 3.16589V3.16589Z"
                          fill="#EA4335"
                        />
                      </svg>
                      <span class="ml-4">Sign in with Google</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="authState.isAuthed" class="">
              <div class="bg-white py-8 px-4  sm:rounded-lg sm:px-10">
                <h2 class="text-lg text-center text-weight-semibold mb-4">
                  Welcome back {{
                    authState.user?.displayName
                  }}
                </h2>
                <div class="mt-0">
                  <div v-if="authState.userIsTenantAdmin">
                    <NuxtLink to="/app/tenant-admin">
                      <button
                        name="continue-to-admin"
                        class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 my-2"
                      >
                        <span class="ml-4">Continue to Admin Panel</span>
                      </button>
                    </NuxtLink>
                  </div>
                  <div v-if="authState.userIsTenantAdmin">
                    <NuxtLink to="/app/user">
                      <button
                        name="continue-to-account"
                        class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 my-2"
                      >
                        <span class="ml-4">Continue to My Account</span>
                      </button>
                    </NuxtLink>
                  </div>
                  <div v-if="!authState.userIsTenantAdmin">
                    <NuxtLink to="/onboarding">
                      <button
                        name="continue-to-onboarding"
                        class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 my-2"
                      >
                        <span class="ml-4">Onboarding</span>
                      </button>
                    </NuxtLink>
                  </div>
                  <div>
                    <NuxtLink to="/login/welcome">
                      <button
                        name="continue-to-welcome"
                        class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 my-2"
                      >
                        <span class="ml-4">Continue</span>
                      </button>
                    </NuxtLink>
                  </div>
                </div>
                <div class="mt-6">
                  <div class="relative">
                    <div class="absolute inset-0 flex items-center">
                      <div class="w-full border-t border-gray-300" />
                    </div>
                    <div class="relative flex justify-center text-sm">
                      <span class="px-2 bg-white text-gray-500">Or</span>
                    </div>
                  </div>
                  <div>
                    <button
                      name="sign-out"
                      class="w-full mt-4 inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                      @click="$signOut()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      <span class="ml-4">Sign Out</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </client-only>

          <div class="text-sm font-medium text-gray-500 dark:text-gray-400">
            Not registered? <NuxtLink to="/sign-up" class="text-blue-700 hover:underline dark:text-primary-500">
              Create
              account
            </NuxtLink>
          </div>

          <!-- <form class="mt-8 space-y-6" action="#">
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                                email</label>
                            <input type="email" name="email" id="email"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                placeholder="name@company.com">
                        </div>
                        <div>
                            <label for="password"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                                password</label>
                            <input type="password" name="password" id="password" placeholder="••••••••"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                        </div>
                        <div class="flex items-start">
                            <div class="flex items-center h-5">
                                <input id="remember" aria-describedby="remember" name="remember" type="checkbox"
                                    class="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600">
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="remember" class="font-medium text-gray-900 dark:text-white">Remember
                                    me</label>
                            </div>
                            <a href="#"
                                class="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500">Lost
                                Password?</a>
                        </div>
                        <button type="submit"
                            class="w-full px-5 py-3 text-base font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Login
                            to your account</button>
                        <div class="text-sm font-medium text-gray-500 dark:text-gray-400">
                            Not registered? <a class="text-primary-700 hover:underline dark:text-primary-500">Create
                                account</a>
                        </div>
                        </form> -->
        </div>
      </div>
    </div>

    <!-- <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <span class="sr-only">WeTix - Sign In</span>
                <img class="mx-auto h-10 w-auto drop-shadow" src="~/assets/wetix-logo.svg" alt="WeTix" />
                <h2 v-if="!authState.isAuthed" class="mt-8 text-center text-3xl font-bold text-gray-900">Sign in to your
                    account</h2>

                <p v-if="!authState.isAuthed" class="mt-2 text-center text-sm text-gray-600">Sign in using a magic link
                    sent to your email, or login with Google.</p>
            </div>

            <client-only>
                <div v-if="!authState.isAuthed" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                        <div class="mt-6">
                            <div class="relative">
                                <div class="absolute inset-0 flex items-center">
                                    <div class="w-full border-t border-gray-300" />
                                </div>
                                <div class="relative flex justify-center text-sm">
                                    <span class="px-2 bg-white text-gray-500">Or</span>
                                </div>
                            </div>
                            <div>
                                <button name="sign-in-with-google" @click="initGoogleLoginPopup"
                                    class="w-full mt-4 inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    <svg width="19" height="18" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17 8.18371C17 7.63989 16.9551 7.09314 16.8591 6.55814H9.16046V9.63879H13.5691C13.3862 10.6324 12.7983 11.5113 11.9376 12.0698V14.0687H14.5678C16.1123 12.6754 17 10.6177 17 8.18371Z"
                                            fill="#4285F4" />
                                        <path
                                            d="M9.16042 16C11.3617 16 13.2182 15.2916 14.5707 14.0687L11.9406 12.0698C11.2088 12.5578 10.2641 12.8341 9.16342 12.8341C7.03409 12.8341 5.22865 11.4261 4.58085 9.53299H1.8667V11.5936C3.25227 14.2951 6.07438 16 9.16042 16V16Z"
                                            fill="#34A853" />
                                        <path
                                            d="M4.57786 9.53298C4.23596 8.53941 4.23596 7.46353 4.57786 6.46996V4.40933H1.8667C0.709065 6.66985 0.709065 9.33309 1.8667 11.5936L4.57786 9.53298V9.53298Z"
                                            fill="#FBBC04" />
                                        <path
                                            d="M9.16042 3.16589C10.3241 3.14825 11.4487 3.57743 12.2914 4.36523L14.6217 2.0812C13.1462 0.72312 11.1878 -0.0235267 9.16042 -1.02057e-05C6.07438 -1.02057e-05 3.25227 1.70493 1.8667 4.40932L4.57785 6.46995C5.22265 4.57394 7.03109 3.16589 9.16042 3.16589V3.16589Z"
                                            fill="#EA4335" />
                                    </svg>
                                    <span class="ml-4">Sign in with Google</span>
                                </button>

                            </div>

                        </div>
                    </div>
                </div>

                <div v-if="authState.isAuthed" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <h2 class="text-lg text-center text-weight-semibold mb-4">Welcome back {{
                                authState.user?.displayName
                        }}</h2>
                        <div class="mt-0">
                            <div v-if="authState.userIsTenantAdmin">
                                <NuxtLink to="/app/tenant-admin">
                                    <button name="continue-to-admin"
                                        class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 my-2">
                                        <span class="ml-4">Continue to Admin Panel</span>
                                    </button>
                                </NuxtLink>
                            </div>
                            <div v-if="authState.userIsTenantAdmin">
                                <NuxtLink to="/account">
                                    <button name="continue-to-account"
                                        class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 my-2">
                                        <span class="ml-4">Continue to My Account</span>
                                    </button>
                                </NuxtLink>
                            </div>
                            <div>
                                <NuxtLink to="/login/welcome">
                                    <button name="continue-to-welcome"
                                        class="w-full flex disabled:opacity-50 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 my-2">
                                        <span class="ml-4">Continue</span>
                                    </button>
                                </NuxtLink>
                            </div>
                        </div>
                        <div class="mt-6">
                            <div class="relative">
                                <div class="absolute inset-0 flex items-center">
                                    <div class="w-full border-t border-gray-300" />
                                </div>
                                <div class="relative flex justify-center text-sm">
                                    <span class="px-2 bg-white text-gray-500">Or</span>
                                </div>
                            </div>
                            <div>
                                <button name="sign-out" @click="$signOut()"
                                    class="w-full mt-4 inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                    </svg>
                                    <span class="ml-4">Sign Out</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </client-only>
            </div> -->

    <TransitionRoot as="template" :show="authState.isEmailLoginInProgress">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="authState.cancelLoginWithEmailPasswordless()">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            >
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-orange-100">
                  <InboxIcon class="h-6 w-6 text-orange-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    Check
                    your email
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Please click the link in the email we just
                      sent you to complete login.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
                  @click="authState.cancelLoginWithEmailPasswordless()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <NotificationBasic />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { InboxIcon } from '@heroicons/vue/outline'
import { useAuth } from '@vueuse/firebase/useAuth'
import { useAuthState } from '~/stores/auth'

const { $sendEmailSignInLink, $firebaseAuth, $signInWithGooglePopup, ssrContext, $signOut } = useNuxtApp()

// Auth state
const authState = useAuthState()

// Composable to watch ID token and set cookie.
useIDTokenChange()

// useAuth from vueUse
const userIsAuthenticated = ref(false)
const userObject = ref({})
if (!ssrContext) {
  const { isAuthenticated, user } = useAuth($firebaseAuth)
  userIsAuthenticated.value = isAuthenticated
  userObject.value = user
  console.log('Client side - auth state:', isAuthenticated, user.value)
} else {
  console.log('SSR - Not getting auth state')
}

const email = ref('')
const password = ref('')

// Function to initialise passwordless login flow
const initPasswordlessLogin = async () => {
  if (!email.value) { return console.error('No email entered') }
  // Send email sign in link
  // await $sendEmailSignInLink(email.value)
  // Show check email prompt
  authState.doLoginWithEmailPasswordless(email.value)
}

// Do login with Google via popup
const initGoogleLoginPopup = async () => {
  console.log('initGoogleLoginPopup')
  await $signInWithGooglePopup()
}

</script>

<script lang="ts">
export default {
  layout: false
}
</script>
